export default {
  getAuthorizationHeaders(state) {
    if (state.authentication) {
      return state.authentication
    }
  },
  isLoading(state) {
    return state.loading
  },
  getUser(state) {
    if (state.user !== null) {
      return state.user
    }
  },
  getUserId(state) {
    return state.userId
  },
  getCredits(state) {
    if (state.credits) {
      return state.credits
    }
  },
  getPhoneCountries(state) {
    if (state.phoneCountries) {
      return state.phoneCountries
    }
  },
  isTeamProfileAvailable(state) {
    return state.teamProfileAvailable === true
  },
  isMain(state) {
    if (state.user) {
      return state.user.isMain === true
    } else {
      return true
    }
  },
  isAllowedToOrder(state) {
    if (state.user) {
      return state.user.allowedToOrder === true
    } else {
      return false
    }
  },
  isAllowedToPayOnAccount(state) {
    if (state.user) {
      return state.user.allowedToPayOnAccount === true
    } else {
      return false
    }
  },
  isAllowedToCreateBulkInvite(state) {
    if (state.user) {
      return state.user.allowedToCreateBulkInvite === true
    } else {
      return false
    }
  },
  getShowWelcomeMessage(state) {
    if (state.user) {
      return state.user.showWelcome === true
    }
  }
}
