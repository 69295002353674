import state from './profileTest/state'
import mutations from './profileTest/mutations'
import getters from './profileTest/getters'
import actions from './profileTest/actions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
