import api from '../../api/'
import { localStorageSessionTokenKey } from '../../constants'

export default {
  setAuthentication({ commit }, authentication) {
    commit('UPDATE_AUTHENTICATION', authentication)
  },
  clearAuthentication({ commit }) {
    commit('UPDATE_AUTHENTICATION', undefined)
    localStorage.setItem(localStorageSessionTokenKey, null)
  },
  setUser({ commit, dispatch }, user) {
    commit('UPDATE_USER', user.customData)
    commit('UPDATE_USER_ID', user.entityId)
    commit('SET_LOADING', false)
  },
  getActiveUser({ commit }) {
    commit('SET_LOADING', true)
    return api()
      .get('/reseller/current')
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_USER', data.customData)
          commit('UPDATE_USER_ID', data.entityId)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          return 'No user active'
        }
      })
      .catch(() => {
        commit('UPDATE_USER', null)
        commit('SET_LOADING', false)
        return 'Error updating user'
      })
  },
  loadCredits({ commit }) {
    commit('SET_LOADING', true)
    return api()
      .get('/reseller/credits')
      .then(({ data }) => {
        if (data.success) {
          const credits = data.customData.products.filter(credit => credit.resellerProduct.testType !== null)

          commit('UPDATE_CREDITS', credits)
          commit('UPDATE_TEAM_PROFILE_AVAILABILITY', data.customData.teamProfileAvailable === true)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          return 'Failed to load credits'
        }
      })
      .catch(() => {
        commit('UPDATE_CREDITS', null)
        commit('SET_LOADING', false)
        return 'Failed to load credits'
      })
  },
  loadPhoneCountries({ commit }) {
    return api()
      .get('/reseller/phone-countries')
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_PHONE_COUNTRIES', data.customData)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          return 'Failed to load phone-countries'
        }
      })
      .catch(() => {
        commit('UPDATE_PHONE_COUNTRIES', null)
        commit('SET_LOADING', false)
        return 'Failed to load phone-countries'
      })
  },
  signout({ commit }) {
    commit('SET_LOADING', true)
    return api()
      .get('/reseller/logout')
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_USER', undefined)
          commit('UPDATE_USER_ID', null)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          return 'logout failed'
        }
      })
      .catch(() => {
        commit('UPDATE_USER', null)
        commit('UPDATE_USER_ID', null)
        commit('SET_LOADING', false)
      })
  }
}
