<script>
export default {
  props: {
    gtmId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      created: false
    }
  },
  watch: {
    gtmId: {
      handler: 'createTagManager',
      immediate: true
    }
  },
  methods: {
    createTagManager() {
      /* eslint-disable */
      if (this.gtmId && this.created === false) {
        ;(function(w, d, s, l, i) {
          w[l] = w[l] || []
          w[l].push({
            'gtm.start': new Date().getTime(),
            'event': 'gtm.js'
          })
          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : ''
          j.async = true
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
          f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', this.gtmId)
        this.created = true
      }
    }
  },
  render() {
    return ''
  }
}
</script>
