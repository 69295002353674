/* istanbul ignore file */
// 1. Define route components.
const Home = () => import('../pages/Home')
const PageNotFound = () => import('../pages/404')
const Login = () => import('../pages/Login')
const Register = () => import('../pages/Register')
const ConfirmRegistration = () => import('../pages/Account/ConfirmRegistration')
const ForgotPassword = () => import('../pages/ForgotPassword')
const ResetPassword = () => import('../pages/ResetPassword')
const Welcome = () => import('../pages/Account/Welcome')
const MyData = () => import('../pages/Account/MyData')
const MyAccounts = () => import('../pages/Account/MyAccounts')
const MyAccount = () => import('../pages/Account/MyAccount')
const MyOrders = () => import('../pages/Account/MyOrders')
const MyAnalyzes = () => import('../pages/Account/MyAnalyzes')
const Order = () => import('../pages/Order')
const Payment = () => import('../pages/Payment')
const PaymentOnAccount = () => import('../pages/PaymentOnAccount')
const ProfileTest = () => import('../pages/ProfileTest')
const Download = () => import('../pages/Download')

// 2. Define some routes
const routes = [
  { path: '*', component: PageNotFound },
  {
    path: '/',
    redirect: {
      name: 'Home'
    },
    meta: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      title: 'DISC Boulevard',
      metaTags: [
        {
          name: 'description',
          content:
            'In ons portaal schaf je voordelige DISC analyses aan, ook wel de DISC test genoemd. Hiermee breng je het voorkeursgedrag van de respondent in kaart. - Purchase affordable DISC analyses, also known as the DISC behavioral test. Map out your preferred behavior style.'
        }
      ]
    }
  },
  {
    name: 'Home',
    path: '/home',
    meta: {
      public: true,
      title: 'DISC Boulevard',
      metaTags: [
        {
          name: 'description',
          content:
            'DISC analyses voor professionals die een DISC certificaat hebben of DISC expert zijn. Je kan direct zelfstandig aan de slag in het online portaal. - DISC analyses for professionals. Order, send and view your DISC reports online.'
        }
      ]
    },
    component: Home
  },
  {
    name: 'Login',
    path: '/inloggen',
    meta: {
      public: true,
      title: 'DISC Boulevard',
      metaTags: [
        {
          name: 'description',
          content:
            'Bestel DISC analyses, zet de vragenlijsten uit en bekijk de DISC rapporten online via jouw persoonlijke account. Log in en start direct. - Order DISC analyses, send out questionnaires and view DISC reports online via your personal account. Log in and start right away.'
        }
      ]
    },
    component: Login
  },
  {
    name: 'Register',
    path: '/registreren',
    meta: {
      public: true,
      title: 'DISC Boulevard',
      metaTags: [
        {
          name: 'description',
          content:
            '✓ Voordelige DISC test, toegankelijk geschreven. Maak een gratis account aan en regel alles vanuit eigen beheer in het online portaal van DISC Boulevard. - ✓ Inexpensive DISC test, accessibly written. Create a free account and arrange everything from the comfort of your own home in DISC Boulevards online portal.'
        }
      ]
    },
    component: Register
  },
  {
    name: 'ForgotPassword',
    path: '/wachtwoord-vergeten',
    meta: {
      public: true
    },
    component: ForgotPassword
  },
  {
    name: 'ResetPassword',
    path: '/reset-wachtwoord',
    meta: {
      public: true
    },
    component: ResetPassword
  },
  {
    name: 'ConfirmRegistration',
    path: '/activeer-account',
    meta: {
      public: true
    },
    component: ConfirmRegistration
  },
  {
    name: 'Welcome',
    path: '/welkom',
    component: Welcome
  },
  {
    name: 'MyData',
    path: '/mijn-account/gegevens',
    component: MyData
  },
  {
    name: 'MyAccounts',
    path: '/mijn-account/accounts',
    component: MyAccounts
  },
  {
    name: 'CreateAccount',
    path: '/mijn-account/accounts/uitnodigen',
    component: MyAccount
  },
  {
    name: 'EditAccount',
    path: '/mijn-account/accounts/:accountId',
    component: MyAccount
  },
  {
    name: 'MyAnalyzes',
    path: '/mijn-account/analyses',
    component: MyAnalyzes
  },
  {
    name: 'MyOrders',
    path: '/mijn-account/bestellingen',
    component: MyOrders
  },
  {
    name: 'Order',
    path: '/bestellen',
    component: Order
  },
  {
    name: 'Payment',
    path: '/bestellen/betaling',
    component: Payment
  },
  {
    name: 'PaymentOnAccount',
    path: '/bestellen/betaald-op-rekening',
    component: PaymentOnAccount
  },
  {
    name: 'ProfleTest',
    path: '/profiel-test',
    component: ProfileTest,
    meta: {
      public: true,
      header: false
    }
  },
  {
    name: 'Download',
    path: '/download-report',
    component: Download,
    meta: {
      public: true,
      header: false
    }
  }
]

export default routes
