import VueRouter from 'vue-router'
import Vue from 'vue'
import VueRouterMetaTags from '@bachdgvn/vue-router-meta-tags'
import routes from './routes'

Vue.use(VueRouter)

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
  mode: 'history',
  routes
})
// This callback runs before every route change, including on page load.
router.beforeEach(VueRouterMetaTags.update)

export default router
