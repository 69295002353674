import state from './account/state'
import mutations from './account/mutations'
import getters from './account/getters'
import actions from './account/actions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
