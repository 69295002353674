export default {
  isLoading(state) {
    return state.loading
  },
  isPublicBulkInvite(state) {
    return state.profileTest !== null && state.profileTest.publicBulkInvite
  },
  getPriceInclVat(state) {
    return state.profileTest !== null && state.profileTest.publicBulkInvite && state.profileTest.priceInclVat > 0
      ? state.profileTest.priceInclVat
      : false
  },
  isPaymentFailed(state) {
    // not success or pending is handeled as failed
    return (
      state.profileTest !== null &&
      state.profileTest.paymentStatus !== null &&
      state.profileTest.paymentStatus !== undefined &&
      state.profileTest.paymentStatus !== 'pending' &&
      state.profileTest.paymentStatus !== 'success'
    )
  },
  isPaymentPending(state) {
    return state.profileTest !== null && state.profileTest.paymentStatus !== undefined && state.profileTest.paymentStatus === 'pending'
  },
  getPaymentStatus(state) {
    if (state.profileTest !== null && state.profileTest.paymentStatus !== undefined) {
      return state.profileTest.paymentStatus
    }
  },
  requiresPayment(state, getters) {
    return getters.getPaymentStatus !== 'success' && getters.isPublicBulkInvite && getters.getPriceInclVat
  },
  getAccountData(state) {
    if (state.profileTest !== null && state.profileTest.account !== undefined) {
      return state.profileTest.account
    } else {
      return {}
    }
  },
  getInstructions(state, getters) {
    return state.profileTest.testTypes[getters.getCurrentTestTypeIndex].instructions
  },
  getLanguageCode(state) {
    if (state.profileTest !== null && state.profileTest.account !== undefined) {
      return state.profileTest.account.languageCode
    } else {
      return 'nl'
    }
  },
  getCurrentQuestion(state, getters) {
    if (state.profileTest !== null) {
      const currentQuestionId = state?.profileTest?.currentQuestion
      if (currentQuestionId) {
        return getters.getQuestionById(currentQuestionId)
      } else {
        return getters.getQuestions[0]
      }
    }
  },
  getCurrentTestTypeIndex(state, getters) {
    for (var index in state.profileTest.testTypes) {
      if (state.profileTest.testTypes[index].questions.find(q => q.id === getters.getCurrentQuestionId)) {
        return parseInt(index)
      }
    }
    return 0
  },
  getQuestionById: (state, getters) => questionId => {
    return state.profileTest.testTypes[getters.getCurrentTestTypeIndex].questions.find(q => q.id === questionId)
  },
  getCurrentQuestionId(state) {
    return state?.profileTest?.currentQuestion
  },
  getCurrentQuestionIndex(state, getters) {
    if (state.profileTest && state.profileTest.testTypes) {
      return state.profileTest.testTypes[getters.getCurrentTestTypeIndex].questions.findIndex(q => getters.getCurrentQuestion.id === q.id)
    }
  },
  getCurrentQuestionNumber(state, getters) {
    return getters.getCurrentQuestionIndex + 1
  },
  getLastTestTypeIndex(state) {
    return parseInt(state.profileTest.testTypes.length - 1)
  },
  isLastTestType(state, getters) {
    return getters.getCurrentTestTypeIndex === getters.getLastTestTypeIndex
  },
  isLastQuestionOfTestType(state, getters) {
    if (getters.getQuestions) {
      return getters.getCurrentQuestionIndex + 1 === getters.getQuestions.length
    }
  },
  isLastQuestion(state, getters) {
    if (getters.getQuestions) {
      return getters.isLastTestType && getters.isLastQuestionOfTestType
    }
  },
  getOverallStatus(state) {
    if (state.profileTest !== null && state.profileTest.overallStatus !== undefined) {
      return state.profileTest.overallStatus
    }
  },
  getQuestions(state, getters) {
    if (state.profileTest !== null && state.profileTest.testTypes[getters.getCurrentTestTypeIndex].questions !== undefined) {
      return state.profileTest.testTypes[getters.getCurrentTestTypeIndex].questions
    }
  },
  getProgress(state, getters) {
    if (getters.getQuestions) {
      let current
      const total = getters.getQuestions.length + 2

      switch (getters.getOverallStatus) {
        case 'INVITATION_SEND':
          current = 0
          break
        case 'USER_DATA_APPROVED':
          current = 1
          break
        case 'FINISH_CONFIRMED':
          current = total
          break
        default:
          current = getters.getCurrentQuestionIndex + 2
      }
      return Math.round((current / total) * 100)
    }
  }
}
