import axios from 'axios'
import store from '../store/store'
import router from '../router/router'
import { localStorageSessionTokenKey } from '../constants'

export default function() {
  const backendUrl = store.getters['runtime-config/getConfigValue']('BACKEND_BASE_URL')
  const authToken = store.getters['account/getAuthorizationHeaders']

  const headers = {}

  const sessionToken = localStorage.getItem(localStorageSessionTokenKey)
  if (sessionToken) {
    headers.Authorization = `Bearer ${sessionToken}`
  }

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`
  }

  const api = axios.create({
    baseURL: backendUrl,
    headers
  })

  /**
   * Response interceptor to check for 401 Error response code
   */
  api.interceptors.response.use(
    response => {
      const xAuthToken = response.headers['x-auth-token']
      if (xAuthToken) {
        localStorage.setItem(localStorageSessionTokenKey, xAuthToken)
        store.dispatch('account/setAuthentication', xAuthToken)
      }
      const { data } = response
      if (
        +data.errorCode === 401 ||
        (response.data.success === false && response.data.flashMessage.toLowerCase() === 'errors.general.unauthorized')
      ) {
        store
          .dispatch('account/signout')
          .then(() => {
            router.push({
              name: 'Login',
              query: { reason: data.errorCode }
            })
          })
          .catch(e => {
            router.push({
              name: 'Login',
              query: { reason: e.message }
            })
          })
      } else {
        return response
      }
    },
    error => {
      // if (error.response.status >= 400 && error.response.status < 500) {
      if (error.response.status === 401) {
        store
          .dispatch('account/signout')
          .then(() => {
            router
              .push({
                name: 'Login',
                query: { reason: error.statusCode }
              })
              .catch(() => {})
          })
          .catch(e => {
            router
              .push({
                name: 'Login',
                query: { reason: e.message }
              })
              .catch(() => {})
          })
      }
      return Promise.reject(error)
    }
  )

  return api
}
