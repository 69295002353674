export default {
  SET_LOADING(state, loadingValue) {
    state.loading = loadingValue
  },
  UPDATE_PROFILE_TEST(state, profileTest) {
    state.profileTest = profileTest
  },
  UPDATE_STATUS(state, status) {
    state.profileTest.overallStatus = status
  }
}
