export default {
  UPDATE_AUTHENTICATION(state, authentication) {
    state.authentication = authentication
  },
  SET_LOADING(state, loadingValue) {
    state.loading = loadingValue
  },
  UPDATE_USER(state, user) {
    state.user = user
  },
  UPDATE_USER_ID(state, id) {
    state.userId = id
  },
  UPDATE_CREDITS(state, credits) {
    state.credits = credits
  },
  UPDATE_PHONE_COUNTRIES(state, phoneCountries) {
    state.phoneCountries = phoneCountries
  },
  UPDATE_TEAM_PROFILE_AVAILABILITY(state, value) {
    state.teamProfileAvailable = value
  }
}
