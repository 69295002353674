var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading:fullscreen",
            value: !_vm.configLoaded || _vm.loading,
            expression: "!configLoaded || loading",
            arg: "fullscreen"
          }
        ],
        staticClass: "vue-app u-height-100"
      },
      [
        _vm.configLoaded && _vm.translationsLoaded
          ? _c("div", { staticClass: "content" }, [
              _vm.showHeader
                ? _c("div", { staticClass: "top" }, [
                    _c("div", { staticClass: "o-container" }, [
                      _c("div", { staticClass: "o-grid" }, [
                        _c("div", { staticClass: "o-grid__col" }, [
                          _vm.isLoggedIn
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "top__link",
                                      attrs: { href: "javascript:void(0)" },
                                      on: { click: _vm.signOut }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("menu_signout")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-popover",
                                    {
                                      ref: "myAccountMenu",
                                      attrs: {
                                        placement: "bottom",
                                        trigger: "hover"
                                      }
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "popover__item",
                                          attrs: { to: { name: "MyData" } }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("menu_my_data")))]
                                      ),
                                      _vm._v(" "),
                                      _vm.isMain
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass: "popover__item",
                                              attrs: {
                                                to: { name: "MyAccounts" }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("menu_my_accounts")
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "popover__item",
                                          attrs: { to: { name: "MyAnalyzes" } }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("menu_my_analyses"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.isAllowedToOrder
                                        ? _c(
                                            "router-link",
                                            {
                                              staticClass: "popover__item",
                                              attrs: {
                                                to: { name: "MyOrders" }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("menu_my_orders"))
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName: "v-popover:myAccountMenu",
                                          arg: "myAccountMenu"
                                        }
                                      ],
                                      staticClass: "top__link"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("menu_my_account")))]
                                  ),
                                  _vm._v(" "),
                                  _vm.isAllowedToOrder
                                    ? _c(
                                        "router-link",
                                        { attrs: { to: { name: "Order" } } },
                                        [
                                          _c(
                                            "el-button",
                                            { attrs: { type: "primary" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("cta_order"))
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "top__link",
                                      attrs: { to: { name: "Login" } }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("menu_signin")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "top__link",
                                      attrs: { to: { name: "Register" } }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("menu_register")))]
                                  )
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "languages" },
                            _vm._l(_vm.languages, function(item, index) {
                              return _c(
                                "a",
                                {
                                  key: index,
                                  staticClass: "language",
                                  class: {
                                    "language--active":
                                      item.code === _vm.languageCode
                                  },
                                  attrs: { href: "?lang=" + item.code }
                                },
                                [_vm._v(_vm._s(item.displayName))]
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "header u-pv-20" }, [
                _c("div", { staticClass: "o-container" }, [
                  _c("div", { staticClass: "o-grid" }, [
                    _c(
                      "div",
                      { staticClass: "o-grid__col u-3/12 u-mv-5 u-pl-5" },
                      [
                        _c("router-link", { attrs: { to: { name: "Home" } } }, [
                          _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: require("@a/images/logo.svg"),
                              alt: "Disc Boulevard"
                            }
                          })
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "o-grid__col u-9/12" })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "u-bgcolor--secondary u-pv-30" }, [
                _c("div", { staticClass: "o-container" }, [
                  _c("div", { staticClass: "o-grid" }, [
                    _c(
                      "div",
                      { staticClass: "o-grid__col" },
                      [_c("router-view")],
                      1
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("google-tag-manager", {
          attrs: { "gtm-id": _vm.$configValue("GTM_ID") }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }