import Vue from 'vue'
import Vuex from 'vuex'
import account from './store.account'
import profileTest from './store.profileTest'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    account,
    profileTest
  }
})

export default store
