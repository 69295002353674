import '../scss/main.scss'
import Vue from 'vue'
import App from '../js/templates/App.vue'
import store from '../js/store/store'
import router from './router/router'
import VueI18n from 'vue-i18n'
import VueCurrencyFilter from 'vue-currency-filter'
import runtimeConfig from '@jdi/vue-runtime-config'
import VueGtag from 'vue-gtag'
import { MessageBox, Notification, Loading } from 'element-ui'
const { directive: loadingDirective, service: loadingService } = Loading
const msgbox = MessageBox
const { alert, confirm, prompt } = msgbox

Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.prototype.$loading = loadingService
Vue.prototype.$msgbox = msgbox
Vue.prototype.$alert = alert
Vue.prototype.$confirm = confirm
Vue.prototype.$prompt = prompt
Vue.prototype.$notify = Notification

Vue.use(runtimeConfig, {
  store,
  dispatchLoad: true // false = default
})

Vue.use(VueGtag, {
  config: {
    id: 'UA-192788674-1'
  }
})

Vue.use(loadingDirective)
Vue.use(VueI18n)

/**
 * For more information about the specific options
 * @url https://vuejs.org/v2/api/
 *
 * During development you want this to be true, so that you are aware that the build includes warnings
 * @bool false Vue.config.productionTip
 *
 * During development you want this to be true, so that you can use Developer Tools
 * @bool true Vue.config.devtools
 *
 * During development you want this to be false, so that Vue gives you hints about your code
 * @bool true Vue.config.silent
 *
 * Set this to true to enable component init, compile, render and patch performance tracing in the browser devtool performance/timeline panel.
 * Only works in development mode and in browsers that support the performance.mark API.
 * @bool false Vue.config.performance
 */

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.silent = true
  Vue.config.performance = false
} else {
  Vue.config.productionTip = false
  Vue.config.devtools = true
  Vue.config.silent = false
  Vue.config.performance = false
}

const i18n = new VueI18n({
  lazy: true,
  locale: 'nl',
  fallbackLocale: 'nl',
  silentTranslationWarn: true
})

/* eslint-disable no-new */
new Vue({
  el: '#vueApp',
  components: {
    App
  },
  router,
  i18n,
  store,
  template: `<App />`
})
