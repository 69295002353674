import api from '../../api/'
import { localStorageSessionTokenKey } from '../../constants'

export default {
  loadCurrent({ commit }, payload) {
    commit('SET_LOADING', true)
    localStorage.setItem(localStorageSessionTokenKey, null)
    let args = []
    if (payload.testId) {
      args += `&t=${payload.testId}`
    }
    if (payload.platformId) {
      args += `&p=${payload.platformId}`
    }
    if (payload.bulkId) {
      args += `&b=${payload.bulkId}`
    }
    return api()
      .get(`/profile-tests/current?h=${payload.token}${args}`)
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_PROFILE_TEST', data.customData)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          throw new Error('No active profile-test')
        }
      })
      .catch(() => {
        commit('UPDATE_PROFILE_TEST', null)
        commit('SET_LOADING', false)
        throw new Error('Error updating profile-test')
      })
  },
  updateAccount({ commit }, payload) {
    commit('SET_LOADING', true)
    return api()
      .put(`profile-tests/account${payload.args}`, payload.data)
      .then(({ data }) => {
        if (data.success) {
          if (data.customData.url !== undefined) {
            commit('SET_LOADING', false)
            window.location = data.customData.url
          } else {
            commit('UPDATE_PROFILE_TEST', data.customData)
            commit('SET_LOADING', false)
          }
        } else {
          throw new Error('Failed to update payment')
        }
      })
      .catch(() => {
        commit('SET_LOADING', false)
        throw new Error('Failed to update account')
      })
  },
  updatePayment({ commit }, payload) {
    commit('SET_LOADING', true)
    return api()
      .put(`profile-tests/payment-result`)
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_PROFILE_TEST', data.customData)
          commit('SET_LOADING', false)
        } else {
          throw new Error('Failed to update payment')
        }
      })
      .catch(() => {
        commit('SET_LOADING', false)
        throw new Error('Failed to update account')
      })
  },
  startTest({ commit }, payload) {
    commit('UPDATE_STATUS', 'IN_PROGRESS')
  },
  updateAnswer({ commit }, payload) {
    commit('SET_LOADING', true)
    return api()
      .put('/profile-tests/answer', payload)
      .then(({ data }) => {
        if (data.success) {
          commit('UPDATE_PROFILE_TEST', data.customData)
          commit('SET_LOADING', false)
        } else {
          commit('SET_LOADING', false)
          throw new Error('Failed to update answer')
        }
      })
      .catch(() => {
        commit('SET_LOADING', false)
        throw new Error('Failed to update answer')
      })
  }
}
